.app {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: start;
}

.app > .spacer {
    flex-grow: 1;
}

.app-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.navigation-bar {
    display: flex;
    flex-direction: row;
    align-items: right;
    spacer: 8px;
    padding: 8px;
    background: white;

}




.app-header-logo {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

.app-header .title {
    padding: 8px;
    font-size: 20px;
    font-weight: 900;
    color: #0F172A;

}

.app-header .header-icon {
    max-height: 64px;

}

.app-header > .spacer {
    flex-grow: 1;
}

.app-header > .header-text {
    color: #64748B;
    padding: 16px;
}

.body {
    font-size: 1em;
    font-weight: normal;
    line-height: 1.5em;
}

.body-grey {
    font-size: 1em;
    font-weight: normal;
    text-align: center;
    line-height: 1.5em;
    color: #8E8E93;
}



a:link {
    text-decoration: none;
    color: #5856D6;
}

.external-link {
    text-decoration: underline;
}

.social-bar {
    display: flex;
    flex-direction: row;
    align-items: left;
    spacer: 16px;
    padding-top: 40px;
}


.social-thumbnail {
    height: 32px;
    width: 32px;
}

.social-link {
padding: 16px;
}

.footer-thumbnail {
    height: 20px;
    width: 20px;
}


.footer-link {
padding: 16px;
}


.social-link:hover {
background: #F2F2F7;
border-radius: 8px;
}

.social-link:focused {
background: #E5E5EA;
box-shadow: inset 0px 0px 0px 2px #5856D6;
}




.h1 {
    font-size: 3em;
    line-height: 1.25em;
    font-weight: 700;
    color: #000000;
    padding-bottom: 1em;
}

.h2 {
    font-size: 1.5em;
    line-height: 1.5em;
    font-weight: 500;
    color: #3C3C43;
}

.h2.underline {
    background-image: linear-gradient(to bottom, transparent 50%, #FFCC00 50%);
    padding: .1em .2em;
}




.h3 {
    font-size: 2em;
    line-height: 1.25em;
    font-weight: 700;
    color: #000000;
    padding-top: 40px;
    padding-bottom: 0.5em;
    self-align: left;

}


.h4 {
    font-size: 1.5em;
    line-height: 1.25em;
    font-weight: 600;
    color: #000000;
    padding-top: 40px;
    self-align: left;

}




.title-download {
    font-size: 1.5em;
    line-height: 1.25em;
    font-weight: 700;
    color: #0F172A;
    padding-top: 4em;
    padding-bottom: 2em;
    align-self: center;
    text-align: center;
}

.subtitle-download {
    font-size: 1em;
    line-height: 1.25em;
    font-weight: 500;
    color: #64748B;
    align-self: center;
    text-align: center;
    padding-bottom: 1em;
}


.secondary-download-button {
    font-size: 1em;
    font-weight: 700;
    flex-direction: row;
    color: #007AFF;
    user-select: none;
    cursor: pointer;
}


.header-text:hover {
    background: #F1F5F9;
    border-radius: 8px;
}

.hero-text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: end;
    max-width: 640px;
    padding: 16px;
    padding-bottom: 40px;
}

.hero-image {
    max-width: 640px;
    width: 100%;
    height: auto;
}

.hero-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 40px;
    max-width: 1440px;
    align-self: left;
}

.app-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: fit-content;
    height: fit-content;
    align-self: center;
}

.app-footer > .footer-text {
    color: #64748B;
    padding: 0.5em;
    margin: 0.5em;
}

.footer-text:hover {
    background: #F1F5F9;
    border-radius: 8px;
}

.tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1em;
    font-weight: 600;
    color: #3C3C43;
    padding: 6px 12px;
    margin: 4px;
    border-radius: 8px;
    user-select: none;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tab:hover {
    color: black;
    background: #F1F5F9;
}


.tab:focus {
    background: #F2F2F7;
    box-shadow: inset 0px 0px 0px 2px #5856D6;
}

.tab:active {
    background: #F1F5F9;
}

.legal {
    width: 100%;
    max-width: 620px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 240px;

}

.title-legal {
    font-size: 2em;
    font-weight: 900;
    padding-top: 40px;
    color: #1E293B;

}

.date-legal {
    font-size: 1.25em;
    font-style: italic;
    padding-top: 0.5em;
    padding-bottom: 4em;
    color: #64748B;

}

.paragraph-title {
    font-size: 1.5em;
    font-weight: 700;
    padding-top: 2em;
    color: #1E293B;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
}

.legal-text {
    font-size: 1.25em;
    line-height: 1.5em;
    padding-top: 1em;
    padding-left: 16px;
    padding-right: 16px;
    font-weight: 500;
    color: #64748B;
    width: 100%;
}


.card-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    gap: 24px;
    padding-top: 1rem;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 16px;
   border: 1px solid #E5E5EA;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 40px;
    background: white;
    max-width: 640px;
}

.container {
  padding: 2px 16px;
}

.card-title {
    font-size: 1.5rem;
    font-weight: 700;
    padding-bottom: 0.5rem;
}

.card-date {
    font-size: 0.75rem;
    font-weight: 400;
    padding-bottom: 0.5rem;
    color: #8E8E93;
    padding-bottom: 0.5rem;
}

.chip-plugin {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: auto;
    height: auto;
    font-weight: 600;
    font-size: 13px;
    line-height: 1.25rem;
    letter-spacing: 0.02em;
    color: #1C1C1E;
    padding: 2px 12px;
    margin-bottom: 1rem;
    background: #F2F2F7;
    border-radius: 8px;
    flex: none;
    flex-grow: 0;
}

.chip-app {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: auto;
    font-weight: 600;
    font-size: 13px;
    line-height: 1.25rem;
    letter-spacing: 0.02em;
    color: #1C1C1E;
    padding: 2px 12px;
    margin-bottom: 1rem;
    background: #F2F2F7;
    ;
    border-radius: 8px;
    flex: none;
    flex-grow: 0;
}

.primary-button {
    display: flex;
    flex-direction: row;
    self-align: center;
    height: 2em;
    font-size: 1em;
    font-weight: 500;
    gap: 8px;
    color: #6261FB;
    padding: 16px 0px;
    cursor: pointer;
    text-decoration: underline 4px;
    text-underline-offset: 8px;
}

.primary-button:hover {
    display: flex;
    flex-direction: row;
    font-size: 1em;
    font-weight: 500;
    padding: 16px 0px;
    cursor: pointer;
    text-decoration: underline 4px;
    text-underline-offset: 8px;
}


.secondary-button {
    display: flex;
    flex-direction: row;
    align-self: end;
    width: fit-content;
    font-size: 1em;
    font-weight: 600;
    padding: 8px 16px;
    color: black;
    border: #C6C6C8 1px solid;
    border-radius: 8px;
    cursor: pointer;

}

.secondary-button:hover {
    display: flex;
    flex-direction: row;
    font-size: 1em;
    font-weight: 600;
    background: #F2F2F7;
    cursor: pointer;

}

.secondary-button::before {
    content: url("./icon_download.svg");
    height:24px;
    padding-right: 4px;
}







.customTable {
    margin-top: 40px;
    width: 100%;
    background-color: white;
    border-collapse: collapse;
    border-width: 1px;
    border-color: #c6c6c8;
    border-radius: 16px;
    color: #000000;
    gap: 2em;
    padding-bottom: 120px;
    white-space: nowrap;

}

.customTable tr td:nth-child(2) {
    text-align: end;
    width: 100%;
    padding-right: 2rem;
    color: #3c3c43;
    font-weight: 600;

}

.customTable tr td:nth-child(3) {
    color: #3c3c43;

}


.customTable td {
    padding-bottom: 2rem;
}



.about-intro {
    padding-bottom: 40px;
}

.selfie {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    border-radius: 12px;
}

.divider {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    padding-top: 4rem;
    padding-bottom: 4rem;
    max-width: 10rem;

}